<template>
  <div class="document">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        v-if="showMotor"
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col >
          <div class="form-item">
            <span>{{isEndorsement?'Endorsement ':''}}Cover Note</span>
            <!--            {{isEndorsement?'Endorsement':''}}-->
            <el-form-item prop="covernote_document_file_id">
              <file-container-card
                :edit="true"
                card
                :fileData="{
                  url: formData.covernote_document_file_url,
                  client_name: formData.covernote_document_file_client_name
                }"
                :otherData="{
                  idKey: 'covernote_document_id',
                  urlKey: 'covernote_document_file_url',
                  client_name:'covernote_document_file_client_name'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />
              <!--              <v-upload v-model="formData.covernote_document_file_id"></v-upload>-->
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
      <el-row
        v-if="showMotor"
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <div class="form-item ">
            <span>{{isEndorsement?'Endorsement ':''}}Cover Note Number</span>
            <el-form-item prop="covernote_no">
              <v-input
                v-model="formData.covernote_no"
                @blur="handlerCheckCovernote"/>
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <div class="form-item ">
            <span>{{isEndorsement?'Endorsement ':''}}Policy</span>
            <el-form-item prop="policy_document_file_id">
              <file-container-card
                :edit="true"
                :fileData="{
                  url: formData.policy_document_file_url,
                  client_name: formData.policy_document_client_name
                }"
                :otherData="{
                  idKey: 'policy_document_id',
                  urlKey: 'policy_document_file_url',
                  client_name:'policy_document_client_name'
                }"
                @delete="handlerDeleteFile"
                @uploadSuccess="handlerUploadSuccess" />

              <!--              <v-upload v-model="formData.policy_document_file_id"></v-upload>-->
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <div class="form-item ">
            <span>{{isEndorsement?'Endorsement ':''}}Policy Number </span>
            <el-form-item prop="policy_no">
              <v-input
                v-model="formData.policy_no"
                @change="handlerPolicyNumber"/>
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState,mapActions} from 'vuex'
import {checkPolicyNumber,checkCovernote} from '@api/policy'
export default {
  name: 'policyDocument',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    classType:{
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    },
    companyID:{
      type: [Number,String],
      default: ''
    }
  },
  data(){
    return{
      oldFormDataLock:-1,
      formData:{
        policy_no: '',
        covernote_no: '',
        policy_document_id: '',
        covernote_document_id: '',
        policy_document_file_url:'',
        covernote_document_file_url:'',
      }
    }
  },
  computed:{
    ...mapState('quotation', ['productID','quoteInformationForm']),
    list(){
      return this.globalOptions.document_type
    },
    isEdit(){
      let {is_edit=''} = this.quoteInformationForm||{}
      return is_edit?true:false
    },
    documentList(){
      // let list = {
      //   10:this.documentOptions,
      //   11:this.contractorDocumentOptions,
      //   12:this.travelDocumentOptions,
      //   13:this.travelDocumentOptions,
      //   14:this.employeeDocumentOptions,
      //   15:this.businessDocumentOptions,
      //   16:this.travelDocumentOptions,
      //   17:this.conEmployeeDocumentOptions,
      //   18:this.travelDocumentOptions
      // }
      let result = []
      let arr =this.list
      arr.some(item=>{
        if (item.class_id == this.classType) {
          item.data.some(client=>{
            if(client.client_type===this.clientType)
            {
              result = client.items
              // return  this.clientType==1?true:false
            }
          })
        }
      })
      return result
      return list[this.productID]
    },
    showMotor(){
      return this.productID==10
    },
    isEndorsement()
    {
      return this.form.policy_type ==203||this.$route.query.isEndorsement=='true'
    },
    isQueryEndorsement(){
      return this.$route.query.isEndorsement=='true'
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        // if(this.formDataLock!==this.oldFormDataLock)
        // {
        this.oldFormDataLock = this.formDataLock
        Object.keys(this.formData).some(key => {
          if (key in val) {
            this.formData[key] = val[key]
          }
        })
        let {policy_document={},covernote_document={}} =val||{}
        if(policy_document){
          let {url,file_id,client_name}=policy_document
          this.$set(this.formData,'policy_document_file_url',url)
          this.$set(this.formData,'policy_document_client_name',client_name)
          this.$set(this.formData,'policy_document_id',file_id)
        }
        if(covernote_document){
          let {url,file_id,client_name}=covernote_document
          this.formData.covernote_document_file_url = url
          this.formData.covernote_document_file_client_name = client_name
          this.formData.covernote_document_id = file_id

        }
        // }
      }
    },
    companyID:{
      immediate:true,
      handler(val){
        if(val&&!this.isEdit){
          this.handlerPolicyNumber()
          this.handlerCheckCovernote()
        }
      }
    }
  },
  methods:{
    handlerCheckCovernote(){
      if(this.isEndorsement)
        return
      if(this.formData.covernote_no)
        this.network().checkCovernote()
    },
    handlerPolicyNumber(){
      if(this.isEndorsement)
        return
      if(this.formData.policy_no)
        this.network().checkPolicyNumber()
    },
    submitForm(formName){
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('getFormData', formName, this.formData)
        }
      })
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, '')
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$refs['form'].clearValidate([idKey])
    },
    handlerDelete({otherData}){
      let {index} = otherData||{}
      this.formData.documents.splice(index,1)
      this.$refs.upload.clearFiles()
    },
    handlerDocumentSuccess({ data, result, otherData }){
      const { file_id,path,url } = data
      this.formData.documents.push({file_id,client_name:path,url:url})

    },
    openFile(url){
      if(url){
        window.open(url,'_blank')
      }
    },
    network(){
      return{
        checkCovernote: async () => {
          let params = {
            covernote_no:this.formData.covernote_no,
            company_id:this.companyID,
          }
          let { data } = await checkCovernote(params)
          if(data.repeated)
            this.$ConfirmBox({
              title: '',
              message: '“Cover Note Number” has been stored in our system. Do you want to continue?',
              confirmBtn:'Yes',
              cancelBtn:'No',
              isCancel:true,
            }).then(() => {
            })
        },
        checkPolicyNumber: async () => {
          let params = {
            policy_no:this.formData.policy_no,
            company_id:this.companyID,
          }
          let { data } = await checkPolicyNumber(params)
          if(data.repeated)
            this.$ConfirmBox({
              title: '',
              message: '“Policy Number” has been stored in our system. Do you want to continue?',
              confirmBtn:'Yes',
              cancelBtn:'No',
              isCancel:true,
            }).then(() => {
            })
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.document{
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  .document-file{
    .flex{
      margin: 5px 0;
      align-items: center;
      span{
        font-weight: normal!important;
      }
      i{
        margin-right: 10px;
      }
    }
  }
  .hr-inline{
    margin: 15px 0;
  }
}
</style>
