var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[(_vm.showMotor)?_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v(_vm._s(_vm.isEndorsement?'Endorsement ':'')+"Cover Note")]),_c('el-form-item',{attrs:{"prop":"covernote_document_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"card":"","fileData":{
                url: _vm.formData.covernote_document_file_url,
                client_name: _vm.formData.covernote_document_file_client_name
              },"otherData":{
                idKey: 'covernote_document_id',
                urlKey: 'covernote_document_file_url',
                client_name:'covernote_document_file_client_name'
              }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],1):_vm._e(),(_vm.showMotor)?_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v(_vm._s(_vm.isEndorsement?'Endorsement ':'')+"Cover Note Number")]),_c('el-form-item',{attrs:{"prop":"covernote_no"}},[_c('v-input',{on:{"blur":_vm.handlerCheckCovernote},model:{value:(_vm.formData.covernote_no),callback:function ($$v) {_vm.$set(_vm.formData, "covernote_no", $$v)},expression:"formData.covernote_no"}})],1)],1)])],1):_vm._e(),_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v(_vm._s(_vm.isEndorsement?'Endorsement ':'')+"Policy")]),_c('el-form-item',{attrs:{"prop":"policy_document_file_id"}},[_c('file-container-card',{attrs:{"edit":true,"fileData":{
                url: _vm.formData.policy_document_file_url,
                client_name: _vm.formData.policy_document_client_name
              },"otherData":{
                idKey: 'policy_document_id',
                urlKey: 'policy_document_file_url',
                client_name:'policy_document_client_name'
              }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],1),_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v(_vm._s(_vm.isEndorsement?'Endorsement ':'')+"Policy Number ")]),_c('el-form-item',{attrs:{"prop":"policy_no"}},[_c('v-input',{on:{"change":_vm.handlerPolicyNumber},model:{value:(_vm.formData.policy_no),callback:function ($$v) {_vm.$set(_vm.formData, "policy_no", $$v)},expression:"formData.policy_no"}})],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }