export const downloadFile = (res) => {
  const blob = new Blob([res.data])
  const contentDisposition = res.headers['Content-Disposition'] || res.headers['content-disposition']
  const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
  let fileName = patt.exec(contentDisposition)[1]
  //  避免返回 name 携帶雙引號
  fileName = fileName.replace(/\"/g, '')

  const elink = document.createElement('a')
  elink.download = fileName
  elink.style.display = 'none'
  elink.href = window.URL.createObjectURL(blob)
  document.body.appendChild(elink)
  elink.click()
  window.URL.revokeObjectURL(elink.href)
  document.body.removeChild(elink)
}